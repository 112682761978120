import React from "react";
import { makeStyles } from "@material-ui/core";
// import Footer from "./Footer";
import TopBar from "src/layouts/DashboardLayout/TopBar";
const useStyles = makeStyles((theme) => ({
  root: {},
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
         <TopBar />
      <div>{children}</div>
      {/* <Footer /> */}
    </div>
  );
};

export default MainLayout;
