/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import { useLocation, matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  List,
  ListSubheader,
  makeStyles,
  Button,
  Avatar,
  Typography,
  IconButton,
  ListItem,
} from "@material-ui/core";
import { Menu } from "react-feather";
import Logo from "src/component/Logo";
import NavItem from "src/layouts/DashboardLayout/NavBar/NavItem";

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    height: "100%",
    background: "#fabc12",
  },
  avatar: {
    cursor: "pointer",
    width: 50,
    height: 50,
  },
  avatarBig: {
    cursor: "pointer",
    width: 150,
    height: 150,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  buttonImg: {
    width: 40,
    height: 40,
    backgroundColor: "#0099FF",
    "& img": {
      width: "30px",
      height: "31px",
    },
  },
  buySwap: {
    borderRadius: "50px",
    backgroundColor: "#fff",
    padding: "4px 15px 4px 1px ",
    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.05)",
    height: "50px",
    fontSize: "17px",
    lineHeight: "27px",
    color: "#0099FF",
    fontWeight: "600",
    "@media (max-width: 767px)": {
      fontSize: "12px",
      width: "100%",
      lineHeight: "25px",
    },
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  login: {
    border: "4px solid #FABC12",
  },
  connectWallet: {
    borderRadius: "50px",
    border: "1px solid #EBEBEB",
    backgroundColor: "#fff",
    padding: "4px 15px 4px 14px ",
    height: "50px",
    fontSize: "14px",
    lineHeight: "19px",
    color: "##464255",
    fontWeight: "600",
    margin: "0 15px",
    fontFamily: "'Montserrat', sans-serif",
    "@media (max-width: 767px)": {
      width: "100%",
      margin: "0 !important",
      fontSize: "12px",
      lineHeight: "19px",
    },
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
}));

const NavBar = ({ sections }) => {
  console.log(sections);
  const classes = useStyles();
  const [rightBar, setRightBar] = useState(false);
  const location = useLocation();
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Logo width="132" />
        </Box>
        <Box py={2}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
          <List>
            <ListItem>
              {" "}
              <Button
                className={classes.buySwap}
                startIcon={
                  <Avatar
                    className={classes.buttonImg}
                    src={"images/pancake.svg"}
                  />
                }
              >
                Buy on PancakeSwap
              </Button>
            </ListItem>
            <ListItem>
              {" "}
              <Button className={classes.connectWallet}>
                Connect to Wallet
              </Button>
            </ListItem>
            <ListItem>
              {" "}
              <IconButton
                aria-label="delete"
                size="small"
                className={classes.login}
              >
                {" "}
                <Avatar
                  className={classes.loginButton}
                  src={"images/user1.png"}
                />
              </IconButton>
            </ListItem>
          </List>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <IconButton
        onClick={() => {
          setRightBar(!rightBar);
        }}
      >
        <Menu color="#222" style={{ width: 40, height: 40 }} />
      </IconButton>

      <Drawer
        anchor="right"
        classes={{ paper: classes.desktopDrawer }}
        open={rightBar}
        onClose={() => {
          setRightBar(false);
        }}
      >
        {content}
      </Drawer>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
