import React from "react";
import { useHistory } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { Typography, Container } from "@material-ui/core";
import Logo from "src/component/Logo";
export default function ({ buttonClick }) {
  const history = useHistory();
  return (
    <div className="navbar">
      <Container maxWidth="lg">
        <nav>
          <input type="checkbox" id="check" />

          <label for="check" className="checkbtn">
            <MenuIcon size={22} color="black" />
          </label>
          <label className="logo">
            <Logo width="190" style={{ marginTop: "-25px" }} />
          </label>
          <ul className="navigation">
            <li onClick={() => buttonClick("prison")}>
              <Typography variant="h6" paragraph>
                Gaming Metaverse
              </Typography>
            </li>
            <li onClick={() => buttonClick("rarity")}>
              <Typography variant="h6" paragraph>
                Rarity
              </Typography>
            </li>
            <li onClick={() => buttonClick("roadmap")}>
              <Typography variant="h6" paragraph>
                Roadmap
              </Typography>
            </li>
            <li onClick={() => buttonClick("team")}>
              <Typography variant="h6" paragraph>
                Team
              </Typography>
            </li>
            <li onClick={() => buttonClick("gallery")}>
              <Typography variant="h6" paragraph>
                Gallery
              </Typography>
            </li>
            <li onClick={() => buttonClick("faq")}>
              {" "}
              <Typography variant="h6" paragraph>
                FAQ
              </Typography>
            </li>
          </ul>
        </nav>
      </Container>
    </div>
  );
}
